/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

body {
  font-family: Arial, Helvetica, sans-serif;
  user-select: none;
}

// ion-content {
//   --background: #fefefe;
// }

ion-card {
  .list-ios {
    background: var(--ion-card-background, var(--ion-item-background, var(--ion-background-color, #fff)));
  }

  ion-item-divider {
    --background: var(--ion-card-background, var(--ion-item-background, var(--ion-background-color, #fff)));
  }
}

#main-menu {
  max-width: 1190px;
}

.page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;

  // Padding is used for groups navigation buttons.
  // On mobile they are beneath the content, so padding is not needed.
  @media (max-width: 768px) {
    padding: 0;
  }
}

ion-content {
  --padding-top: 20px;

  @media (max-width: 768px) {
    --padding-top: 0;
  }
}

ion-button:not([size="small"]) {
  --padding-top: 10px;
  --padding-bottom: 10px;
  font-size: min(0.9rem, 48px);
}

ion-card {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
  // border: 2px solid var(--ion-color-light);
  border-radius: 12px;
  margin: 12px;

  ion-card-content {
    padding: 0 10px 16px 10px;
  }

  ion-card-title {
    --color: var(--ion-color-dark);
  }

  ion-card-header {
    .tiny-title {
      font-size: 0.9rem;
      font-weight: normal;
      color: var(--ion-color-medium);
      margin: 0;
    }

    .small-title {
      font-size: 1.1rem;
      font-weight: 600;
      color: var(--ion-color-dark);
      margin: 0 0 4px 0;
    }
  }
}

ion-button {
  --border-radius: 8px;
  min-height: unset;

  &.button-small {
    min-height: 2.1em;
  }

  &.button-outline {
    --border-width: 2px;
    --border-color: rgba(var(--ion-color-medium-rgb), 0.1);
    --color: var(--ion-color-dark);
    --background-hover: var(--ion-color-light);
    --background-activated: var(--ion-color-light);
    --background-focused: var(--ion-color-light);
  }
}

h1 {
  color: var(--ion-color-dark);
  font-weight: bold;
  font-size: 2rem;
}

.user-menu-popover {
  --width: 220px;
}

// CLERK

.cl-modalBackdrop {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(6px);
  pointer-events: none; // Prevent modal background dismiss
}

// Don't apply background dismiss prevention to modal content
.cl-modalContent {
  pointer-events: auto;
}

// Hide modal close button
.cl-modalCloseButton {
  display: none;
}

// Hide clerk banner
#\:r2\:>div>div>div>div>div[class^="cl-internal-"] {
  display: none;
}

.scroll-container {
  height: 300px;
}

.pricing-plans-modal {
  --width: auto;
  --height: auto;
  --max-height: 100vh;
  --overflow: auto;

  .ion-page {
    overflow: auto;
  }
}

.align-center {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

ion-modal.upgrade-confirm {
  --height: 240px;
}

// LS Backdrop
// iframe[allow="payment"] {
//   position: relative !important;
//   width: 400px !important;
//   height: calc(100vh - 40px) !important;
//   border-radius: 20px !important;
//   margin: 20px auto !important;
// }